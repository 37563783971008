<template>
  <div class="data_container">
    <div class="crumbs data_item">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>交易管理</el-breadcrumb-item>
        <el-breadcrumb-item>曲线分解方式</el-breadcrumb-item>
        <el-breadcrumb-item v-if="detailFlag">详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="data_item flex_between" v-if="!detailFlag">
      <div class="flex_start operation">
        <div class="flex_start">
          <div class="search_item flex_start">
            <span class="search_title">模板名称：</span>
             <el-input v-model="creator" placeholder="请输入" />
          </div>


          <div class="search_item flex_start">
            <span class="search_title">曲线类型：</span>
            <el-select
              v-model="calculationType"
              class="select_day"
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeList.data"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>

          <div class="search_item flex_start">
            <el-button type="primary" class="search">查询</el-button>
            <el-button type="info" class="search">重置</el-button>
          </div>
        </div>
      </div>


    </div>

     <div class="data_item flex_between" v-else>
      <div class="flex_between operation">
        <div class="flex_start">
          <div class="search_item flex_start">
            <span class="search_title">模板名称：</span>
             <el-input v-model="creator" placeholder="请输入" />
          </div>


          <div class="search_item flex_start">
            <span class="search_title">选择年份：</span>
            <el-select
              v-model="calculationType"
              class="select_day"
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeList.data"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>

 
        </div>
        
        <div class="flex_start">
         <el-button class="data_btns flex_center" >
          <i class="icon iconfont icon-Icon-bianji"></i>
          <span>编辑</span>
        </el-button>
  
        </div>


      </div>


    </div>

    <template v-if="!detailFlag">
        <!-- <el-radio-group v-model="tabs" class="tab_time">
        <el-radio-button label="标准曲线" />
      <el-radio-button label="自定义曲线" />
       </el-radio-group> -->

      <div class="data_box">
        <el-table
          :data="forecastList.data"
          :header-cell-style="{
            background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
          }"
          :cell-style="{
            background: '#0B183C',
            padding: '15px 0',
            color: '#FFFFFF',
          }"
          class="forecast_table"
        >

          <el-table-column prop="template" label="模板名称" align="center" />
          <el-table-column prop="year" label="所属年度" align="center" />
          <el-table-column prop="type" label="曲线类型" align="center" />
          <el-table-column prop="created" label="创建人" align="center" />
          <el-table-column prop="createTime" label="创建时间" align="center" />
          <el-table-column prop="detail" label="操作" align="center">
            <el-button type="primary" link @click="todetail">详情</el-button>
          </el-table-column>
        </el-table>
<!-- 
        <div class="flex_end">
          <el-pagination
            :page-size="20"
            :pager-count="6"
            layout="prev, pager, next"
            :total="1000"
            class="pages"
          />
        </div> -->
      </div>
    </template>

    <template v-else>
      <div class="data_box">
            <div class="echarts_container">
        <div class="echarts_title_container flex_between">
          <div class="echarts_title">月分日权重</div>
              <el-select
            v-model="priceVal"
            class="select_day"
            placeholder="请选择"
      
          >
            <el-option
              v-for="item in typeList.data"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>

        <div class="flex_between holiday_container">
          <el-table
    
          :data="vacationList.data"
          border 
          :header-cell-style="{
            background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
              borderColor:'#293657'
          }"
          :cell-style="columnStyle"
          class="holiday_table"
         >
          <el-table-column prop="name" label="日期类型" align="center" />
          <el-table-column prop="weekday" label="工作日" align="center" />
          <el-table-column prop="saturday" label="周六" align="center" />
          <el-table-column prop="sunday" label="周日" align="center" />
          <el-table-column prop="holidays" label="法定节假日" align="center" />
          <el-table-column prop="adjust" label="调休节假日" align="center"/>


        </el-table>
        <div ref="weightEchats" id="weightEchats"></div>
        </div>


        <!-- <div class="flex_between holiday_container">
 
          <el-table
    
          :data="propList.data"
          border 
          :header-cell-style="{
            background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
              borderColor:'#293657'
          }"
          :cell-style="columnStyle"
          class="prop_table"
         >
         <template v-for="(item,index) in propList.tableTit" :key="index">
            
          <el-table-column :fixed="index==0?true:false"  :label="item.name" align="center">
            <template #default="scope">
                {{ index==0?scope.row.name:scope.row[item.name] }}
            </template>
          </el-table-column>

         </template>
    
        </el-table>
        <div ref="proportionEchats" id="proportionEchats"></div>
        </div> -->



      </div>

       <div class="echarts_container">
        <div class="echarts_title_container flex_between">
          <div class="echarts_title">日分时比例</div>


           

        </div>

          <div class="flex_start prop_select enter_mode" style="width:100%">
           <span class="flex_start">录入方式：</span>
           <el-select
              v-model="calculationType"
              class="select_day"
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeList.data"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
             <div class="flex_start prop_select">
               <el-tabs
              v-model="paneActive"
              class="forecast_tabs"
              @click="propChart()"
           
            >
              <el-tab-pane
                :label="item.name"
                :name="item.id"
                v-for="(item, index) in paneList.data"
                :key="index"
              ></el-tab-pane>
            </el-tabs>
             </div>
        <div class="flex_between holiday_container">
             <el-table
    
          :data="propList.data"
          border 
          :header-cell-style="{
            background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
              borderColor:'#293657'
          }"
          :cell-style="columnStyle"
          class="prop_table"
         >
         <template v-for="(item,index) in propList.tableTit" :key="index">
            
          <el-table-column :fixed="index==0?true:false"  :label="item.name" align="center">
            <template #default="scope">
                {{ index==0?scope.row.name:scope.row[item.name] }}
            </template>
          </el-table-column>

         </template>
        </el-table>
        <div ref="proportionEchats" id="proportionEchats"></div>
       </div>

       <div class="remark">
          <div class="flex_start">备注：</div>
          <el-input style="width:100%" v-model="textarea" maxlength="150" placeholder="请输入备注" show-word-limit type="textarea" />
       </div>
          

        </div>


      </div>
    </template>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "curve",

  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

 
    let detailFlag = ref(false);
    let creator = ref("");
    let calculationType = ref("");
    let typeList = reactive({
      data: [
        { label: "类型1", value: 1 },
        { label: "类型2", value: 2 },
      ],
    });

    let tabs=ref('标准曲线')

    let paneList=reactive({data:
    [
        {id:1,name:'工作日'},
        {id:2,name:'周六'},
        {id:3,name:'周日'},
        {id:4,name:'法定节假日'},
        {id:5,name:'调休节假日'},
    ]
    
        })

        let paneActive=ref(1)
    

    const todetail = () => {
      detailFlag.value = true;
      if(detailFlag.value){
          weightChart()
          propChart()
      }
    };

    let forecastList = reactive({
      data: [ 
        {template:'统调年分月',year:'2021',type: "年分解曲线",created:'蚂蚁管理员', createTime: "2021/7/23",},
        {template:'统调月分日',year:'2021',type: "月分解曲线",created:'蚂蚁管理员', createTime: "2021/7/23",},
        {template:'统调日分时',year:'2021',type: "日分解曲线",created:'蚂蚁管理员', createTime: "2021/7/23",},

        // {buyer:'惠州市启庆科技有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-08',end:'2028-12',power:'100',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'广东东方面粉有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-01',end:'2028-12',power:'150',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        // {buyer:'珠海市和平物流综合市场有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-03',end:'2028-12',power:'80',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠州市鼎盛荣物业管理有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2023-02',end:'2028-12',power:'160',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠州旭泰工艺实业有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2023-04',end:'2028-12',power:'200',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        // {buyer:'惠州市维斯曼酒店有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-04',end:'2028-12',power:'350',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠州市亿鑫酒店有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-09',end:'2028-12',power:'200',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠州市华晖酒店有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-11',end:'2028-12',power:'60',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        // {buyer:'惠州市西顿光电有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-08',end:'2028-12',power:'80',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠州市科为联创科技有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-09',end:'2028-12',power:'100',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
      ],
    });

    // for (let i = 0; i < 13; i++) {
    //   forecastList.data.push({
    //     id: i + 1,
    //     template: "2022-MD-1",
    //     year: "2022",
    //     type: "月度分解曲线",
    //     created : "蚂蚁管理员",
    //     createTime: "2022.08.09   11:00PM",

    //   });
    // }

    let vacationList=reactive({
        data:[
            {name:'权重',weekday:1,saturday:0.98,sunday:0.92,holidays:0.64,adjust:0.8}
        ]
    })

    let propList=reactive({
        data:[

        ]
    })


    
   
    const columnStyle=(col)=>{
        let styles= {padding:'15px 0',color:'#FFFFFF',borderColor:'#293657'}
        if(col.columnIndex==0){
            styles.background="#022C63"
            return styles
        }else{
            styles.background="#0B183C"
            return styles
        }
    }

    let textarea=ref('')

   
       let weight = reactive({
      options: {
        // tooltip: {
        //   trigger: "axis",
        //   axisPointer: {
        //     type: "shadow",
        //   },
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        legend:{
        data:[],
         type: "scroll",
         icon: "rectangle", //区域块
          textStyle: {
            color: "#FFFFFF",
          },
        },
     dataZoom: [
          {
            type: "inside", //slider表示有滑动块的，inside表示内置的
          },
        ],
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true,
            },

            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
            //   interval:6
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#FFFFFF",
              align: "left",
            },
            axisLine: { onZero: false, show: true },
            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
            },
            splitLine: {
              //修改背景线条样式
              show: true, //是否展示
              lineStyle: {
                color: "#8291A9", //线条颜色
                type: "dashed", //线条样式，默认是实现，dashed是虚线
              },
            },
          },
        ],
        series: [],
      },
    });



    const weightChart = async () => {
      // 渲染图表
      let weightData=JSON.parse(JSON.stringify(weight));
      let propData=JSON.parse(JSON.stringify(weight));

      let array = [];
      let xtitle=[];
      let legendList=['工作日','周六','周日','法定节假日','调休节假日']
      let legendColor=[random(),random(),random(),random()]
      for (let i = 0; i < 30; i++) {
        xtitle.push(i+1+'日')
        let ran=Math.trunc((Math.random() * legendList.length))
        let current={ name:legendList[ran], stack: 'one', type:'bar', data:[],itemStyle: {normal: {color: legendColor[ran]},
            },}
       for(let t =0;t<i+1;t++){
        if(t==i){
        current.data.push(Number(Math.random().toFixed(2)))
        }else{
        current.data.push(0)

        }
       }
      array.push(current)
      }
      weightData.options.legend.data=legendList
      weightData.options.xAxis[0].data=xtitle
      weightData.options.series= array;

    


     await nextTick(() => {
        renderChart("weightEchats",weightData.options)

    

        
  
      })
      
    };

    
    const propChart = async () => {
      // 渲染图表
      let propData=JSON.parse(JSON.stringify(weight));

      let xtitle=[];
      propData.options.series={ type:'bar',color:'#0081FF', data:[]}
        propList.tableTit=[]
        propList.data=[]
        propList.data.push({name:'百分比'})
        propList.tableTit.push({name:'时刻'})
      for (let i = 0; i < 24; i++) {
        xtitle.push(i+'时')
      propData.options.series.data.push(Number((Math.random()*5).toFixed(2)))
      propList.data[0][i+'时']=Number((Math.random()*5).toFixed(2))
      propList.tableTit.push({name:i+'时'})

      }
      propData.options.xAxis[0].data=xtitle
      console.log(propList)
     await nextTick(() => {

        renderChart("proportionEchats",propData.options)

        
  
      })

    }

      const renderChart = (target, maps) => {
      // 渲染图表
      console.log(target)
      console.log(maps)
      let chart = proxy.$echarts.init(document.getElementById(target));
      chart.setOption(maps,true);
      window.onresize = () => {
        chart.resize();
      };
    };

    const random = () => {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      return `rgb(${r}, ${g}, ${b})`;
    };

    onMounted(() => {
      nextTick(() => {
      
      });
    });
    return {
      forecastList,
      detailFlag,
      todetail,
      creator,
      calculationType,
      typeList,
      tabs,
      vacationList,
      columnStyle,
      weight,
     weightChart,
     renderChart,
     random,
     propChart,
     propList,
     paneList,
     paneActive,
     textarea
    };
  },
};
</script>

<style lang="scss" scoped>
.data_box {
  margin-bottom: 15px;
}

.data_title {
  margin: 16px 0;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 1em;

  &::before {
    display: block;
    content: "";
    width: 6px;
    height: 20px;
    background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
    border-radius: 3px 3px 3px 3px;
  }
}

.data_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .data_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
        width: 100%;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
}

.forecast_table {
  width: 100%;
  background: #0b183c;

  border-radius: 8px;
  --el-table-border: 2px solid #090a1f;
  --el-table-tr-bg-color: transparent;

  &:deep(.el-scrollbar) {
    padding: 0 10px 15px;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
  }
  &:deep(.el-table__inner-wrapper::before) {
    background: #090a1f;
  }
}


.search_item {
  margin-right: 30px;

  .search_title {
    white-space: nowrap;
  }
}

.pages {
  margin-top: 25px;
  padding: 4px 0;
  background-color: #1f2935;
  --el-pagination-bg-color: #1f2935;
  border-radius: 6px;
  color: #ffffff;
  &:deep(button:disabled) {
    background-color: #1f2935;
  }
  &:deep(.el-pager li.is-active) {
    color: #ffffff;
    background: #0081ff;
    border-radius: 8px;
    margin: 0;
  }
  &:deep(.btn-prev) {
    color: #ffffff;
  }
  &:deep(.btn-next) {
    color: #ffffff;
  }
  &:deep(.btn-quickprev) {
    color: #ffffff;
  }
  &:deep(.btn-quicknext) {
    color: #ffffff;
  }
}


.tab_time{
 margin: 5px 0;
 margin-bottom: 24px;
 }
 .data_btns {
  background: linear-gradient(180deg, #3090e4 0%, #09d3d5 100%);
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-right: 15px;
  border: none;
  &:deep(.icon) {
    padding-right: 10px;
  }
}  .echarts_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
    background: #0b183c;
    margin-bottom: 30px;
    border-radius: 8px;
    padding: 20px 0;
    //       &:deep(.el-date-editor.el-input__wrapper) {
    //     background: #1f2935;
    //     box-shadow: none;
    //   }

    //   &:deep(.select_day) {
    //     width: 120px;
    //     margin: 0 32px;
    //   }
    //   &:deep(.el-select .el-input__wrapper) {
    //     background: #1f2935;
    //     box-shadow: none;
    //   }
    #weightEchats{
       width: 1000px;
       height: 200px; 
    }
    #proportionEchats{
           width: 800px;
       height: 200px; 
    }
 .echarts_title_container {
      width: 100%;
      &:deep(.el-select .el-input__wrapper) {
        width: 150px;
        background: transparent;
        // box-shadow:none;
        margin-right: 30px;
      }
      .echarts_title {
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-indent: 2em;

        &::before {
          display: block;

          content: "";
          width: 4px;
          height: 25px;
          background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
          border-radius: 0px 2px 2px 0px;
        }
      }
    }
}
.holiday_container{
    width: 100%;
    margin: 24px 0;
    padding: 0 24px;
}
.holiday_table{
  width: 580px;
  border-radius: 4px;
//   border: 1px solid #293657; 
  --el-table-border-color:#293657;
 
}

.prop_table{
      width: 770px;
  border-radius: 4px;
  --el-table-border-color:#293657;
 
}
 .prop_select{
    margin-top: 20px;
     margin-left: 24px;   
  }
  .enter_mode{
     :deep(.el-input__wrapper){
        background-color:transparent
     }
  }
  .forecast_tabs {
  &:deep(.el-tabs__item) {
    color: #ffffff;
  }
  &:deep(.el-tabs__active-bar) {
    background: #36d1ff;
  }

  &:deep(.el-tabs__nav-wrap::after) {
    // width: 1000px;
    background: #8291a9;
    //  position: static !important;
  }
}
.remark{
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  flex-direction: column;
   padding: 0 24px;
   .el-textarea{
    margin-top: 24px;
    :deep(.el-textarea__inner){
      height: 70px;
      background: #0F1B3C;
    }
    :deep(.el-input__count){
      background:transparent
    }
   }
}

</style>
